import VueRouter from 'vue-router'
import store from './store'

// Global --------------------------- --------------------------- --------------------------- ---------------------------
import Home from '@/views/Home'
import page404 from '@/views/404'

// Auth --------------------------- --------------------------- --------------------------- ---------------------------
import Register from '@/views/Auth/Register'
import RegisterAppsumo from '@/views/Auth/RegisterAppsumo'
import RegisterPitchground from '@/views/Auth/RegisterPitchground'

import FreeRegister from '@/views/Auth/FreeRegister'
import AgencyDeal from '@/views/Auth/AgencyDeal'
import TrialRegister from '@/views/Auth/TrialRegister'
import Trial from '@/views/Auth/Trial'

import Login from '@/views/Auth/Login'
import Forgot from '@/views/Auth/Forgot'
import Reset from '@/views/Auth/Reset'
import GeneratePassword from '@/views/Auth/GeneratePassword'
import LoginToken from '@/views/Auth/LoginToken'

// Admin --------------------------- --------------------------- --------------------------- ---------------------------
import AdminDashboard from '@/views/admin/Dashboard'

// import Pricing from '@/views/Pricing'
// import Logout from '@/views/Auth/Logout'

// user --------------------------- --------------------------- --------------------------- ---------------------------
import Dashboard from '@/views/user/Dashboard'
// leadSources
import MySources from '@/views/user/leadSources/mySources'
import NewSource from '@/views/user/leadSources/NewSource'
import EditSources from '@/views/user/leadSources/EditSources'
// leadManagement
import myLeads from '@/views/user/leadManagement/myLeads?v=1'
import qualification from '@/views/user/leadManagement/qualification'
import analytics from '@/views/user/leadManagement/analytics'

import Demo from '@/views/Demo'
// integrations
import Integration from '@/views/user/Integration'
import Destination from '@/views/user/integrations/destination'
import AddDestination from '@/views/user/integrations/addDestination'
import AddMailchimp from '@/views/user/integrations/addMailchimp'
import AddSendinblue from '@/views/user/integrations/addSendinblue'
import AddMailjet from '@/views/user/integrations/addMailjet'

// settings
import settings from '@/views/user/Settings'
import Account	from '@/views/user/Account'
// Checkout
import Checkout from '@/views/user/Checkout/Checkout'
import leadDispatch from '@/views/user/settings/dispatch'

/* import teamMembers from '@/views/user/settings/teamMembers'
import leadScoring from '@/views/user/settings/leadScoring'
import leadDispatch from '@/views/user/settings/leadDispatch'
import emailTemplates from '@/views/user/settings/emailTemplates' */
// end --------------------------- --------------------------- --------------------------- ---------------------------

// Routes
const routes = [
	{ path: '/', name: 'home', component: Home, meta: { auth: undefined, title: 'Lead Management & Lead Generation Software' } },
	{ path: '/register', name: 'register', component: Register, meta: { auth: false, title: 'Register' } },
	{ path: '/appsumo', name: 'registerAppsumo', component: RegisterAppsumo, meta: { auth: false, title: 'Register' } },
	{ path: '/appsumo/:coupon', name: 'registerAppsumoCoupon', component: RegisterAppsumo, meta: { auth: false, title: 'Register' } },
	{ path: '/pitchground', name: 'registerPitchground', component: RegisterPitchground, meta: { auth: false, title: 'Register' } },
	{ path: '/pitchground/:coupon', name: 'registerPitchground', component: RegisterPitchground, meta: { auth: false, title: 'Register' } },
	{ path: '/signup', name: 'signup', component: FreeRegister, meta: { auth: false, title: 'Register' } },
	{ path: '/agency-deal', name: 'agencydeal', component: AgencyDeal, meta: { auth: false, title: 'Agency deal' } },
	{ path: '/register-trial', name: 'register-trial', component: TrialRegister, meta: { auth: false, title: 'Register' } },
	{ path: '/trial', name: 'trial', component: Trial, meta: { auth: false, title: 'Trail' } },

	{ path: '/login', name: 'login', component: Login, meta: { auth: false, title: 'Login' } },
	
	{ path: '/passwordGen', name: 'passwordGen', component: GeneratePassword, meta: { auth: false, title: 'Generate Password' } },
	{ path: '/LoginToken', name: 'LoginToken', component: LoginToken, meta: { auth: false, title: 'Login Token' } },
	
	{ path: '/register/:token', name: 'registerToken', component: Register, meta: { auth: false, title: 'Register' } },
	{ path: '/login/:token', name: 'loginToken', component: Login, meta: { auth: false, title: 'Login' } },
	
	// { path: '/logout', name: 'logout', component: Logout, meta: { auth: true, title: 'Logout' } },
	{ path: '/forgot', name: 'forgot', component: Forgot, meta: { auth: false, title: 'Forgot' } },
	{ path: '/reset/:token', name: 'reset', component: Reset, meta: { auth: false, title: 'Reset Your Password' } },
	
	// { path: '/pricing', name: 'pricing', component: Pricing, meta: { auth: undefined, title: 'Pricing' } },
	
	{ path: '*', 			name: '404',	 	component: page404, 	meta: { auth: undefined, title: '404' } },
	// USER ROUTES ---------------------------------------------------------
	{ path: '/dashboard', name: 'dashboard', component: Dashboard, meta: { auth: true, title: 'Dashboard' } },
	
	// leadSources
	{ path: '/mysources', name: 'mySources', component: MySources, meta: { auth: true, title: 'My Sources' } },
	{ path: '/editsource/:source', name: 'editSource', component: EditSources, meta: { auth: true, title: 'My Sources' } },
	{ path: '/newsource', name: 'newSource', component: NewSource, meta: { auth: true, title: 'New Source' } },
	{ path: '/newsource/:source', name: 'addSource', component: NewSource, meta: { auth: true, title: 'New Source' } },
	// leadManagement
	{ path: '/myleads', 		name: 'myLeads', component: myLeads, meta: { auth: true, title: 'My Leads' } },
	{ path: '/payment/:status', 		name: 'payment', component: myLeads, meta: { auth: true, title: 'My Leads' } },
	{ path: '/myleads/:source', name: 'myLead', component: myLeads, meta: { auth: true, title: 'My Leads' } },
	{ path: '/qualification', name: 'qualification', component: qualification, meta: { auth: true, title: 'Qualification' } },
	{ path: '/analytics', name: 'analytics', component: analytics, meta: { auth: true, title: 'Analytics' } },
	
	//demo
	{path :'/demo', name :'demo', component: Demo, meta:{title:'Get the Demo'}},
	// integrations
	{ path: '/integrations', name: 'integrations', component: Integration, meta: { auth: true, title: 'Integration' } },
    { path: '/integration/:page', name: 'integration', component: Integration, meta: { auth: true, title: 'Integration' } },
    { path: '/destination', name: 'destination', component: Destination, meta: { auth: true, title: 'Destinations' } },
    { path: '/destination/add', name: 'add-destination', component: AddDestination, meta: { auth: true, title: 'Add Destination' } },
    { path: '/destination/add-mailchimp', name: 'add-mailchimp', component: AddMailchimp, meta: { auth: true, title: 'Add Mailchimp' } },
    { path: '/destination/add-sendinblue', name: 'add-sendinblue', component: AddSendinblue, meta: { auth: true, title: 'Add Sendinblue' } },
    { path: '/destination/add-mailjet', name: 'add-mailjet', component: AddMailjet, meta: { auth: true, title: 'Add Mailjet' } },
    { path: '/destination/edit/:id', name: 'edit-destination', component: AddDestination, meta: { auth: true, title: 'Edit Destination' } },
    { path: '/destination/edit-mailchimp/:id', name: 'edit-mailchimp', component: AddMailchimp, meta: { auth: true, title: 'Edit Mailchimp' } },
    { path: '/destination/edit-sendinblue/:id', name: 'edit-sendinblue', component: AddSendinblue, meta: { auth: true, title: 'Edit Sendinblue' } },
    { path: '/destination/edit-mailjet/:id', name: 'edit-mailjet', component: AddMailjet, meta: { auth: true, title: 'Edit Mailjet' } },

	
	// settings
	{ path: '/settings', name: 'settings', component: settings, meta: { auth: true, title: 'Settings' } },
	{ path: '/settings/:page', name: 'setting', component: settings, meta: { auth: true, title: 'Settings' } },
	{ path: '/account',  name: 'Account', component: Account, meta: { auth: true, title: 'Account' } },
	{ path: '/account/:page',  name: 'account', component: Account, meta: { auth: true, title: 'Account' } },
	
	// checkout
	{ path: '/checkout', name: 'checkout', component: Checkout, meta: { auth: true, title: 'Checkout' } },
	{ path: '/leadDispatch', name: 'leadDispatch', component: leadDispatch, meta: { auth: true, title: 'Lead Dispatch' } },
	/* { path: '/teamMembers', name: 'teamMembers', component: teamMembers, meta: { auth: true, title: 'Team Members' } },
	{ path: '/leadScoring', name: 'leadScoring', component: leadScoring, meta: { auth: true, title: 'Lead Scoring' } },
	{ path: '/leadDispatch', name: 'leadDispatch', component: leadDispatch, meta: { auth: true, title: 'Lead Dispatch' } },
	{ path: '/emailTemplates', name: 'emailTemplates', component: emailTemplates, meta: { auth: true, title: 'Email Templates' } }, */
	
	// ADMIN ROUTES
	{ path: '/admin', name: 'admin.dashboard', component: AdminDashboard, meta: { auth: {roles: 'sysadmin', redirect: {name: 'login'}, forbiddenRedirect: '/403'}, title: 'Admin Dashboard' } },
]

const router = new VueRouter({
	history: true,
	// base: process.env.BASE_URL,
	mode: 'history',
	routes,
})


// GOOD
router.beforeEach((to, from, next) => {
	
	let title      = store.state.CompanyName != '' ? store.state.CompanyName +  ' | ' : ''
	document.title = title + to.meta.title || '';
	let valToken   = window.localStorage.getItem('oml-token')
	let storeToken = store.state.userToken
	
	if (to.meta.auth == true) {
		if( localStorage.getItem('oml-token') == "undefined" || storeToken == '' || valToken == null || valToken == '' ) {
			store.state.userToken = ''
			store.state.status = ''
			next('/login')
		} else {
			next()
		}
	}
	else if (to.meta.auth == false) {
		if( localStorage.getItem('oml-token') == "undefined" || storeToken == '' || valToken == null || valToken == '' ) {
			next()
		} else { next('/myLeads') }
	} else if (to.meta.auth == undefined) {
		next()
	} else {
		next('/login')
	}
})

/* router.beforeEach((to, from, next) => {
	if(to.matched.some(record => record.meta.requiresAuth)) {
		if (store.getters.isLoggedIn) {
			next()
			return
		}
		next('/login') 
	} else {
		next() 
	}
}) */



export default router

