import Vue from 'vue'
import VueI18n from 'vue-i18n';

import Fr 	from './Langues/Fr';
import Eng 	from './Langues/Eng';

Vue.use(VueI18n);

const messages = {
    'en': Eng,
    'fr': Fr
};

const i18n = new VueI18n({
	locale			: '', // set locale
	fallbackLocale	: '', // set fallback locale
	messages, // set locale messages
});


// if(navigator.language =="fr-FR"){
	// i18n.locale = 'fr'
	// i18n.fallbackLocale = 'fr'
// }
// console.log(i18n.locale)
export default i18n;
